{
  "data": {
    "gameClient": {
        "observerServerIp": "spectator.pbe1.lol.pvp.net",
        "observerServerPort": 8080,
        "running": true,
        "serverIp": "192.207.0.2",
        "serverPort": 7284,
        "visible": false
    },
    "gameData": {
        "gameId": 4472318042,
        "gameName": "",
        "isCustomGame": false,
        "password": "",
        "playerChampionSelections": [
            {
                "championId": 82,
                "selectedSkinIndex": 30,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": ""
            },
            {
                "championId": 17,
                "selectedSkinIndex": 0,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": "Vurkrein"
            },
            {
                "championId": 106,
                "selectedSkinIndex": 13,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": "DizeIraMortem"
            },
            {
                "championId": 910,
                "selectedSkinIndex": 10,
                "spell1Id": 2202,
                "spell2Id": 2201,
                "summonerInternalName": "JackospiderPBE"
            },
            {
                "championId": 31,
                "selectedSkinIndex": 0,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": "Jalandhari"
            },
            {
                "championId": 72,
                "selectedSkinIndex": 0,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": ""
            },
            {
                "championId": 63,
                "selectedSkinIndex": 0,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": ""
            },
            {
                "championId": 25,
                "selectedSkinIndex": 0,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": ""
            },
            {
                "championId": 72,
                "selectedSkinIndex": 0,
                "spell1Id": 2202,
                "spell2Id": 2201,
                "summonerInternalName": "GuaPiBao"
            },
            {
                "championId": 54,
                "selectedSkinIndex": 0,
                "spell1Id": 2202,
                "spell2Id": 2201,
                "summonerInternalName": ""
            },
            {
                "championId": 10,
                "selectedSkinIndex": 66,
                "spell1Id": 2202,
                "spell2Id": 2201,
                "summonerInternalName": "LePetitHeraldFR"
            },
            {
                "championId": 56,
                "selectedSkinIndex": 26,
                "spell1Id": 2202,
                "spell2Id": 2201,
                "summonerInternalName": "HELP MEAA AAAAAA"
            },
            {
                "championId": 91,
                "selectedSkinIndex": 56,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": "El q te cuento"
            },
            {
                "championId": 876,
                "selectedSkinIndex": 0,
                "spell1Id": 2202,
                "spell2Id": 2201,
                "summonerInternalName": "Its Rio"
            },
            {
                "championId": 28,
                "selectedSkinIndex": 30,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": "Charmaster"
            },
            {
                "championId": 157,
                "selectedSkinIndex": 45,
                "spell1Id": 2201,
                "spell2Id": 2202,
                "summonerInternalName": ""
            }
        ],
        "queue": {
            "allowablePremadeSizes": [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16
            ],
            "areFreeChampionsAllowed": true,
            "assetMutator": "",
            "category": "PvP",
            "championsRequiredToPlay": 38,
            "description": "Arena",
            "detailedDescription": "Arena",
            "gameMode": "CHERRY",
            "gameTypeConfig": {
                "advancedLearningQuests": false,
                "allowTrades": false,
                "banMode": "StandardBanStrategy",
                "banTimerDuration": 38,
                "battleBoost": false,
                "crossTeamChampionPool": false,
                "deathMatch": false,
                "doNotRemove": false,
                "duplicatePick": false,
                "exclusivePick": false,
                "id": 20,
                "learningQuests": false,
                "mainPickTimerDuration": 93,
                "maxAllowableBans": 6,
                "name": "GAME_CFG_TEAM_BUILDER_BLIND_DRAFT",
                "onboardCoopBeginner": false,
                "pickMode": "SimulPickStrategy",
                "postPickTimerDuration": 13,
                "reroll": false,
                "teamChampionPool": false
            },
            "id": 1700,
            "isRanked": true,
            "isTeamBuilderManaged": true,
            "lastToggledOffTime": 0,
            "lastToggledOnTime": 0,
            "mapId": 30,
            "maximumParticipantListSize": 16,
            "minLevel": 0,
            "minimumParticipantListSize": 1,
            "name": "Arena",
            "numPlayersPerTeam": 16,
            "queueAvailability": "Available",
            "queueRewards": {
                "isChampionPointsEnabled": false,
                "isIpEnabled": true,
                "isXpEnabled": true,
                "partySizeIpRewards": []
            },
            "removalFromGameAllowed": false,
            "removalFromGameDelayMinutes": 0,
            "shortName": "Arena",
            "showPositionSelector": false,
            "spectatorEnabled": true,
            "type": "CHERRY"
        },
        "spectatorsAllowed": false,
        "teamOne": [
            {
                "championId": 91,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 3771,
                "puuid": "9fe95dd3-866f-5737-b1cb-783067b90117",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 2179668947,
                "summonerInternalName": "El q te cuento",
                "summonerName": "El q te cuento",
                "teamOwner": false,
                "teamParticipantId": 10
            },
            {
                "championId": 910,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 755,
                "puuid": "e10d7765-76d8-5f5e-855d-4bed202aede8",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 2191181594,
                "summonerInternalName": "JackospiderPBE",
                "summonerName": "JackospiderPBE",
                "teamOwner": false,
                "teamParticipantId": 3
            },
            {
                "championId": 56,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "7626f399-2fb6-5a39-9a9a-2b072d42aee1",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3269753018279360,
                "summonerInternalName": "HELP MEAA AAAAAA",
                "summonerName": "HELP MEAA AAAAAA",
                "teamOwner": false,
                "teamParticipantId": 9
            },
            {
                "championId": 63,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "5cb7d359-841c-57e1-af10-37de7639f43f",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3375451320108192,
                "summonerInternalName": "",
                "summonerName": "",
                "teamOwner": false,
                "teamParticipantId": 5
            },
            {
                "championId": 72,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "12ce40bd-750d-5c83-88f9-8abdeda17ed4",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 2975482611566432,
                "summonerInternalName": "GuaPiBao",
                "summonerName": "GuaPiBao",
                "teamOwner": false,
                "teamParticipantId": 7
            },
            {
                "championId": 31,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 27,
                "puuid": "3fe4711d-00b2-50df-9c54-e5eddcb96199",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 2193442143,
                "summonerInternalName": "Jalandhari",
                "summonerName": "Jalandhari",
                "teamOwner": false,
                "teamParticipantId": 4
            },
            {
                "championId": 876,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 4225,
                "puuid": "8b3bfa31-070d-5b2a-813a-fcc0b72cec7d",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 2160269288,
                "summonerInternalName": "Its Rio",
                "summonerName": "Its Rio",
                "teamOwner": false,
                "teamParticipantId": 11
            },
            {
                "championId": 82,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 3797,
                "puuid": "7a9be801-ec76-59ac-951d-6a3495f98b48",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3364809196668352,
                "summonerInternalName": "",
                "summonerName": "",
                "teamOwner": false,
                "teamParticipantId": 1
            },
            {
                "championId": 10,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "02e4c79b-5134-58ac-bbef-e1882d5cf432",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3167787282359968,
                "summonerInternalName": "LePetitHeraldFR",
                "summonerName": "LePetitHeraldFR",
                "teamOwner": false,
                "teamParticipantId": 9
            },
            {
                "championId": 157,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "7d052b88-7455-5b5a-a593-7fd6b71b704e",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3472143438759712,
                "summonerInternalName": "",
                "summonerName": "",
                "teamOwner": false,
                "teamParticipantId": 13
            },
            {
                "championId": 25,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "32c3b6bf-00a7-5540-9239-5e56258a6f11",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3420796288247360,
                "summonerInternalName": "",
                "summonerName": "",
                "teamOwner": false,
                "teamParticipantId": 6
            },
            {
                "championId": 72,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "24faa62d-aef1-504d-9bf5-ae9ea39d46c0",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3373858856986560,
                "summonerInternalName": "",
                "summonerName": "",
                "teamOwner": false,
                "teamParticipantId": 5
            },
            {
                "championId": 28,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 4673,
                "puuid": "841a5631-e56a-5f09-97ac-c5e8feedbcbf",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 2180078433,
                "summonerInternalName": "Charmaster",
                "summonerName": "Charmaster",
                "teamOwner": false,
                "teamParticipantId": 12
            },
            {
                "championId": 17,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 3825,
                "puuid": "c9a4198c-6b01-5620-b603-7e799846a18a",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3350699976001152,
                "summonerInternalName": "Vurkrein",
                "summonerName": "Vurkrein",
                "teamOwner": false,
                "teamParticipantId": 1
            },
            {
                "championId": 54,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 29,
                "puuid": "b1a4c6fd-7c4c-5ed2-8059-87d7c6582949",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3477430860457152,
                "summonerInternalName": "",
                "summonerName": "",
                "teamOwner": false,
                "teamParticipantId": 8
            },
            {
                "championId": 106,
                "lastSelectedSkinIndex": 0,
                "profileIconId": 5294,
                "puuid": "c4817274-43e7-51d7-8c1f-bc14b1ab449a",
                "selectedPosition": "NONE",
                "selectedRole": "NONE.NONE.FILL.UNSELECTED",
                "summonerId": 3012287767741632,
                "summonerInternalName": "DizeIraMortem",
                "summonerName": "DizeIraMortem",
                "teamOwner": false,
                "teamParticipantId": 2
            }
        ],
        "teamTwo": []
    },
    "gameDodge": {
        "dodgeIds": [],
        "phase": "None",
        "state": "Invalid"
    },
    "map": {
        "assets": {
            "champ-select-background-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Shared/sound/music-cs-blindpick-default.ogg",
            "champ-select-flyout-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/GameModeX/img/champ-select-flyout-background.jpg",
            "game-select-icon-active": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/game-select-icon-active.png",
            "game-select-icon-active-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/video/game-select-icon-active.webm",
            "game-select-icon-default": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/game-select-icon-default.png",
            "game-select-icon-disabled": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/GameModeX/img/game-select-icon-disabled.png",
            "game-select-icon-hover": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/game-select-icon-hover.png",
            "game-select-icon-intro-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/video/game-select-icon-intro.webm",
            "gameflow-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/gameflow-background.jpg",
            "gameflow-background-dark": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/gameflow-background-dark.jpg",
            "gameselect-button-hover-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Shared/sound/sfx-gameselect-button-hover.ogg",
            "icon-defeat": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-defeat.png",
            "icon-defeat-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-defeat.png",
            "icon-defeat-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/GameModeX/video/icon-defeat.webm",
            "icon-empty": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/game-select-icon-default.png",
            "icon-hover": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/game-select-icon-hover.png",
            "icon-leaver": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-leaver.png",
            "icon-leaver-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-leaver.png",
            "icon-loss-forgiven-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-loss-forgiven.png",
            "icon-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-v2.png",
            "icon-victory": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-victory.png",
            "icon-victory-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/GameModeX/video/icon-victory.webm",
            "music-inqueue-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/sound/music-inqueue-loop-cherry.ogg",
            "notification-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/GameModeX/img/notification-background.jpg",
            "notification-icon": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/game-select-icon-active.png",
            "parties-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/parties-background.jpg",
            "postgame-ambience-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/sound/sfx-ambience-loop-summonersrift.ogg",
            "ready-check-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/ready-check-background.png",
            "ready-check-background-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/sound/sfx-readycheck-sr-portal.ogg",
            "sfx-ambience-pregame-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/sound/sfx-ambience-loop-summonersrift.ogg",
            "social-icon-leaver": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-leaver.png",
            "social-icon-victory": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/icon-victory.png",
            "tutorial-bg": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Cherry/img/one-page-tutorial-bg.jpg"
        },
        "categorizedContentBundles": {},
        "description": "Queue up solo or partner with your friends in this multi-team game mode. Face against your foes in chaotic battles across diverse arenas.",
        "gameMode": "CHERRY",
        "gameModeName": "Arena",
        "gameModeShortName": "Arena",
        "gameMutator": "",
        "id": 30,
        "isRGM": false,
        "mapStringId": "RoW",
        "name": "Arena",
        "perPositionDisallowedSummonerSpells": {},
        "perPositionRequiredSummonerSpells": {
            "any": {
                "spells": [
                    2201
                ]
            }
        },
        "platformId": "",
        "platformName": "",
        "properties": {
            "suppressRunesMasteriesPerks": true
        }
    },
    "phase": "InProgress"
  },
  "eventType": "Update",
  "uri": "/lol-gameflow/v1/session"
}